// @flow
import {
    DEPARTMENT_LIST,
    DEPARTMENT_LIST_SUCCESS,
    DEPARTMENT_LIST_FAILED,
    DEPARTMENT_ADD,
    DEPARTMENT_ADD_SUCCESS,
    DEPARTMENT_ADD_FAILED,
    DEPARTMENT_UPDATE,
    DEPARTMENT_UPDATE_SUCCESS,
    DEPARTMENT_UPDATE_FAILED,
    DEPARTMENT_DELETE,
    DEPARTMENT_DELETE_SUCCESS,
    DEPARTMENT_DELETE_FAILED,
    DAILYACTIVITY_LIST,
    DAILYACTIVITY_LIST_SUCCESS,
    DAILYACTIVITY_LIST_FAILED,
    DAILYACTIVITY_ADD,
    DAILYACTIVITY_ADD_SUCCESS,
    DAILYACTIVITY_ADD_FAILED,
    DAILYJOBDES_ADD,
    DAILYJOBDES_ADD_SUCCESS,
    DAILYJOBDES_ADD_FAILED,
    DAILYACTIVITY_UPDATE,
    DAILYACTIVITY_UPDATE_SUCCESS,
    DAILYACTIVITY_UPDATE_FAILED,
    DAILYACTIVITY_DELETE,
    DAILYACTIVITY_DELETE_SUCCESS,
    DAILYACTIVITY_DELETE_FAILED,
    WEEKLYACTIVITY_LIST,
    WEEKLYACTIVITY_LIST_SUCCESS,
    WEEKLYACTIVITY_LIST_FAILED,
    WEEKLYACTIVITY_ADD,
    WEEKLYACTIVITY_ADD_SUCCESS,
    WEEKLYACTIVITY_ADD_FAILED,
    WEEKLYJOBDES_ADD,
    WEEKLYJOBDES_ADD_SUCCESS,
    WEEKLYJOBDES_ADD_FAILED,
    WEEKLYACTIVITY_UPDATE,
    WEEKLYACTIVITY_UPDATE_SUCCESS,
    WEEKLYACTIVITY_UPDATE_FAILED,
    WEEKLYACTIVITY_DELETE,
    WEEKLYACTIVITY_DELETE_SUCCESS,
    WEEKLYACTIVITY_DELETE_FAILED,
    MONTHLYACTIVITY_LIST,
    MONTHLYACTIVITY_LIST_SUCCESS,
    MONTHLYACTIVITY_LIST_FAILED,
    MONTHLYACTIVITY_ADD,
    MONTHLYACTIVITY_ADD_SUCCESS,
    MONTHLYACTIVITY_ADD_FAILED,
    MONTHLYJOBDES_ADD,
    MONTHLYJOBDES_ADD_SUCCESS,
    MONTHLYJOBDES_ADD_FAILED,
    MONTHLYACTIVITY_UPDATE,
    MONTHLYACTIVITY_UPDATE_SUCCESS,
    MONTHLYACTIVITY_UPDATE_FAILED,
    MONTHLYACTIVITY_DELETE,
    MONTHLYACTIVITY_DELETE_SUCCESS,
    MONTHLYACTIVITY_DELETE_FAILED,
    USERDEPARTMENT_LIST,
    USERDEPARTMENT_LIST_SUCCESS,
    USERDEPARTMENT_LIST_FAILED,
    USERTEAM_LIST,
    USERTEAM_LIST_SUCCESS,
    USERTEAM_LIST_FAILED,
    DAILYACTIVITYEDITLIST,
    DAILYACTIVITYEDITLIST_SUCCESS,
    DAILYACTIVITYEDITLIST_FAILED,
    DAILYACTIVITYEDITADD,
    DAILYACTIVITYEDITADD_SUCCESS,DAILYACTIVITYEDITADD_FAILED,DAILYACTIVITYEDITUPDATE,DAILYACTIVITYEDITUPDATE_SUCCESS,DAILYACTIVITYEDITUPDATE_FAILED

} from './constants';

import { getLoggedInUser } from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
};

type DepartmentAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value ?: boolean };

const Department = (state: State = INIT_STATE, action: DepartmentAction) => {
    switch (action.type) {
        case DEPARTMENT_LIST:
            return { ...state, listloading: true };
        case DEPARTMENT_LIST_SUCCESS:
            return { ...state, department: action.payload, listloading: false, error: null };
        case DEPARTMENT_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case DEPARTMENT_ADD:
            return { ...state, loading: true };
        case DEPARTMENT_ADD_SUCCESS:
            return { ...state, departmentAdd: action.payload, loading: false, error: null };
        case DEPARTMENT_ADD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case DEPARTMENT_UPDATE:
            return { ...state, loading: true };
        case DEPARTMENT_UPDATE_SUCCESS:
            return { ...state, departmentUpdate: action.payload, loading: false, error: null };
        case DEPARTMENT_UPDATE_FAILED:
            return { ...state, error: action.payload, loading: false };
        case DEPARTMENT_DELETE:
            return { ...state, loading: true };
        case DEPARTMENT_DELETE_SUCCESS:
            return { ...state, departmentDelete: action.payload, loading: false, error: null };
        case DEPARTMENT_DELETE_FAILED:
            return { ...state, error: action.payload, loading: false };

            
            case DAILYACTIVITY_LIST:
                return { ...state, listloading: false };
            case DAILYACTIVITY_LIST_SUCCESS:
                return { ...state, dailyactivity: action.payload, listloading: false, error: null };
            case DAILYACTIVITY_LIST_FAILED:
                return { ...state, error: action.payload, loading: false };
            case DAILYACTIVITY_ADD:
                return { ...state, loading: true };
            case DAILYACTIVITY_ADD_SUCCESS:
                return { ...state, dailyactivityAdd: action.payload, loading: false, error: null };
            case DAILYACTIVITY_ADD_FAILED:
                return { ...state, error: action.payload, loading: false };
    
                case DAILYJOBDES_ADD:
                    return { ...state, loading: true };
                case DAILYJOBDES_ADD_SUCCESS:
                    return { ...state, dailyjobdesAdd: action.payload, loading: false, error: null };
                case DAILYJOBDES_ADD_FAILED:
                    return { ...state, error: action.payload, loading: false };
    
    
            case DAILYACTIVITY_UPDATE:
                return { ...state, loading: true };
            case DAILYACTIVITY_UPDATE_SUCCESS:
                return { ...state, dailyactivityUpdate: action.payload, loading: false, error: null };
            case DAILYACTIVITY_UPDATE_FAILED:
                return { ...state, error: action.payload, loading: false };
            case DAILYACTIVITY_DELETE:
                return { ...state, loading: true };
            case DAILYACTIVITY_DELETE_SUCCESS:
                return { ...state, dailyactivityDelete: action.payload, loading: false, error: null };
            case DAILYACTIVITY_DELETE_FAILED:
                return { ...state, error: action.payload, loading: false };    


                case WEEKLYACTIVITY_LIST:
                    return { ...state, listloading: false };
                case WEEKLYACTIVITY_LIST_SUCCESS:
                    return { ...state, weeklyactivity: action.payload, listloading: false, error: null };
                case WEEKLYACTIVITY_LIST_FAILED:
                    return { ...state, error: action.payload, loading: false };
                case WEEKLYACTIVITY_ADD:
                    return { ...state, loading: true };
                case WEEKLYACTIVITY_ADD_SUCCESS:
                    return { ...state, weeklyactivityAdd: action.payload, loading: false, error: null };
                case WEEKLYACTIVITY_ADD_FAILED:
                    return { ...state, error: action.payload, loading: false };
        
                    case WEEKLYJOBDES_ADD:
                        return { ...state, loading: true };
                    case WEEKLYJOBDES_ADD_SUCCESS:
                        return { ...state, weeklyjobdesAdd: action.payload, loading: false, error: null };
                    case WEEKLYJOBDES_ADD_FAILED:
                        return { ...state, error: action.payload, loading: false };
        
        
                case WEEKLYACTIVITY_UPDATE:
                    return { ...state, loading: true };
                case WEEKLYACTIVITY_UPDATE_SUCCESS:
                    return { ...state, weeklyactivityUpdate: action.payload, loading: false, error: null };
                case WEEKLYACTIVITY_UPDATE_FAILED:
                    return { ...state, error: action.payload, loading: false };
                case WEEKLYACTIVITY_DELETE:
                    return { ...state, loading: true };
                case WEEKLYACTIVITY_DELETE_SUCCESS:
                    return { ...state, weeklyactivityDelete: action.payload, loading: false, error: null };
                case WEEKLYACTIVITY_DELETE_FAILED:
                    return { ...state, error: action.payload, loading: false };



                    case MONTHLYACTIVITY_LIST:
                        return { ...state, listloading: false };
                    case MONTHLYACTIVITY_LIST_SUCCESS:
                        return { ...state, monthlyactivity: action.payload, listloading: false, error: null };
                    case MONTHLYACTIVITY_LIST_FAILED:
                        return { ...state, error: action.payload, loading: false };
                    case MONTHLYACTIVITY_ADD:
                        return { ...state, loading: true };
                    case MONTHLYACTIVITY_ADD_SUCCESS:
                        return { ...state, monthlyactivityAdd: action.payload, loading: false, error: null };
                    case MONTHLYACTIVITY_ADD_FAILED:
                        return { ...state, error: action.payload, loading: false };
            
                        case MONTHLYJOBDES_ADD:
                            return { ...state, loading: true };
                        case MONTHLYJOBDES_ADD_SUCCESS:
                            return { ...state, monthlyjobdesAdd: action.payload, loading: false, error: null };
                        case MONTHLYJOBDES_ADD_FAILED:
                            return { ...state, error: action.payload, loading: false };
            
            
                    case MONTHLYACTIVITY_UPDATE:
                        return { ...state, loading: true };
                    case MONTHLYACTIVITY_UPDATE_SUCCESS:
                        return { ...state, monthlyactivityUpdate: action.payload, loading: false, error: null };
                    case MONTHLYACTIVITY_UPDATE_FAILED:
                        return { ...state, error: action.payload, loading: false };
                    case MONTHLYACTIVITY_DELETE:
                        return { ...state, loading: true };
                    case MONTHLYACTIVITY_DELETE_SUCCESS:
                        return { ...state, monthlyactivityDelete: action.payload, loading: false, error: null };
                    case MONTHLYACTIVITY_DELETE_FAILED:
                        return { ...state, error: action.payload, loading: false };



                        case USERDEPARTMENT_LIST:
                            return { ...state, listloading: true };
                        case USERDEPARTMENT_LIST_SUCCESS:
                            return { ...state, userdepartment: action.payload, listloading: false, error: null };
                        case USERDEPARTMENT_LIST_FAILED:
                            return { ...state, error: action.payload, loading: false };

                            case USERTEAM_LIST:
                                return { ...state, listloading: true };
                            case USERTEAM_LIST_SUCCESS:
                                return { ...state, userteam: action.payload, listloading: false, error: null };
                            case USERTEAM_LIST_FAILED:
                                return { ...state, error: action.payload, loading: false };

                                case DAILYACTIVITYEDITLIST:
                                    return { ...state, listloading: true };
                                case DAILYACTIVITYEDITLIST_SUCCESS:
                                    return { ...state, dailyactivityedit: action.payload, listloading: false, error: null };
                                case DAILYACTIVITYEDITLIST_FAILED:
                                    return { ...state, error: action.payload, listloading: false };

                                    //add

                                    case DAILYACTIVITYEDITADD:
                                        return { ...state, listloading: true };
                                    case DAILYACTIVITYEDITADD_SUCCESS:
                                        return { ...state, dailyactivityeditadd: action.payload, listloading: false, error: null };
                                    case DAILYACTIVITYEDITADD_FAILED:
                                        return { ...state, error: action.payload, listloading: false };

                                    //update
                                    case DAILYACTIVITYEDITUPDATE:
                                        return { ...state, listloading: true };
                                    case DAILYACTIVITYEDITUPDATE_SUCCESS:
                                        return { ...state, dailyactivityeditupdate: action.payload, listloading: false, error: null };
                                    case DAILYACTIVITYEDITUPDATE_FAILED:
                                        return { ...state, error: action.payload, listloading: false };
        default:
            return { ...state };
    }
};

export default Department;
