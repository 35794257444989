// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';

import {     REMAINDER_LIST,
    REMAINDER_LIST_SUCCESS,
    REMAINDER_LIST_FAILED,
    REMAINDER_ADD,
    REMAINDER_ADD_SUCCESS,
    REMAINDER_ADD_FAILED,
    REMAINDER_UPDATE,
    REMAINDER_UPDATE_SUCCESS,
    REMAINDER_UPDATE_FAILED,
    REMAINDER_DELETE,
    REMAINDER_DELETE_SUCCESS,
    REMAINDER_DELETE_FAILED } from './constants';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    getRemainderListSuccess,
    getRemainderListFailed,
    getRemainderAddSuccess,
    getRemainderAddFailed,
    getRemainderUpdateSuccess,
    getRemainderUpdateFailed,
    getRemainderDeleteSuccess,
    getRemainderDeleteFailed,
} from './actions';

import { getLoggedInUser } from '../../helpers/authUtils';

const remainderAddedSucsess = () => toast.success('Reminder Added Successfully', { transition: Zoom });
const remainderDeletedSuccess = () => toast.success('Reminder Deleted Successfully', { transition: Zoom });
const remainderUpdated = () => toast.info('Reminder Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* RemainderList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.remainderList,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getRemainderListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRemainderListFailed(message));
    }
}

// Remainder Add

function* RemainderAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.remainderAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        remainderAddedSucsess();
        yield put(getRemainderAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRemainderAddFailed(message));
    }
}

// Remainder Update

function* RemainderUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.remainderUpdate + '/' + (data && data.id),
        data: data.data,
    };

    try {
        const response = yield call(ApiCall, options);
        remainderUpdated();
        yield put(getRemainderUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRemainderUpdateFailed(message));
    }
}

// Remainder Delete

function* RemainderDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.remainderDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        remainderDeletedSuccess();
        yield put(getRemainderDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRemainderDeleteFailed(message));
    }
}

export function* watchRemainderList(): any {
    yield takeEvery(REMAINDER_LIST, RemainderList);
}
export function* watchRemainderAdd(): any {
    yield takeEvery(REMAINDER_ADD, RemainderAdd);
}
export function* watchRemainderUpdate(): any {
    yield takeEvery(REMAINDER_UPDATE, RemainderUpdate);
}
export function* watchRemainderDelete(): any {
    yield takeEvery(REMAINDER_DELETE, RemainderDelete);
}

function* authSaga(): any {
    yield all([fork(watchRemainderList), fork(watchRemainderAdd), fork(watchRemainderUpdate), fork(watchRemainderDelete)]);
}

export default authSaga;
