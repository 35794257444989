// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DAILYACTIVITY_LIST, DAILYACTIVITY_ADD,DAILYJOBDES_ADD, DAILYACTIVITY_UPDATE, DAILYACTIVITY_DELETE,DEPARTMENT_LIST, DEPARTMENT_ADD, DEPARTMENT_UPDATE, DEPARTMENT_DELETE, 
    WEEKLYACTIVITY_LIST, WEEKLYACTIVITY_ADD,WEEKLYJOBDES_ADD, WEEKLYACTIVITY_UPDATE, WEEKLYACTIVITY_DELETE,MONTHLYACTIVITY_LIST, MONTHLYACTIVITY_ADD,MONTHLYJOBDES_ADD, MONTHLYACTIVITY_UPDATE, MONTHLYACTIVITY_DELETE,
    USERDEPARTMENT_LIST,
    USERDEPARTMENT_LIST_SUCCESS,
    USERDEPARTMENT_LIST_FAILED,    USERTEAM_LIST,
    USERTEAM_LIST_SUCCESS,
    USERTEAM_LIST_FAILED, DAILYACTIVITYEDITLIST,
    DAILYACTIVITYEDITLIST_SUCCESS,
    DAILYACTIVITYEDITLIST_FAILED, DAILYACTIVITYEDITADD,
    DAILYACTIVITYEDITADD_SUCCESS,DAILYACTIVITYEDITADD_FAILED,DAILYACTIVITYEDITUPDATE,DAILYACTIVITYEDITUPDATE_SUCCESS,DAILYACTIVITYEDITUPDATE_FAILED
 
} from './constants';

import {
    getDepartmentListSuccess,
    getDepartmentListFailed,
    getDepartmentAddSuccess,
    getDepartmentAddFailed,
    getDepartmentUpdateSuccess,
    getDepartmentUpdateFailed,
    getDepartmentDeleteSuccess,
    getDepartmentDeleteFailed,
    getDailyactivityListSuccess,
    getDailyactivityListFailed,
    getDailyactivityAddSuccess,
    getDailyactivityAddFailed,
    getDailyjobdesAddSuccess,
    getDailyjobdesAddFailed,
    getDailyactivityUpdateSuccess,
    getDailyactivityUpdateFailed,
    getDailyactivityDeleteSuccess,
    getDailyactivityDeleteFailed,
    getWeeklyactivityListSuccess,
    getWeeklyactivityListFailed,
    getWeeklyactivityAddSuccess,
    getWeeklyactivityAddFailed,
    getWeeklyjobdesAddSuccess,
    getWeeklyjobdesAddFailed,
    getWeeklyactivityUpdateSuccess,
    getWeeklyactivityUpdateFailed,
    getWeeklyactivityDeleteSuccess,
    getWeeklyactivityDeleteFailed,
    getMonthlyactivityListSuccess,
    getMonthlyactivityListFailed,
    getMonthlyactivityAddSuccess,
    getMonthlyactivityAddFailed,
    getMonthlyjobdesAddSuccess,
    getMonthlyjobdesAddFailed,
    getMonthlyactivityUpdateSuccess,
    getMonthlyactivityUpdateFailed,
    getMonthlyactivityDeleteSuccess,
    getMonthlyactivityDeleteFailed,
    getUserDepartmentList,
    getUserDepartmentListSuccess,
    getUserDepartmentListFailed,
    getUserTeamList,
    getUserTeamListSuccess,
    getUserTeamListFailed,getDailyactivityListEdit,getDailyactivityListEditSuccess,getDailyactivityListEditFailed,
    getDailyactivityAddEdit,
    getDailyactivityAddEditSuccess,
    getDailyactivityAddEditFailed,getDailyactivityUpdateEdit,getDailyactivityUpdateEditSuccess,getDailyactivityUpdateEditFailed
} from './actions';

import { getLoggedInUser } from '../../helpers/authUtils';

const departmentAddedSucsess = () => toast.success('Department Added Successfully', { transition: Zoom });
const departmentDeletedSuccess = () => toast.success('Department Deleted Successfully', { transition: Zoom });
const departmentUpdated = () => toast.info('Department Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
const dailyactivityAddedSucsess = () => toast.success('Daily Activity Added Successfully', { transition: Zoom });
const dailyjobdesAddedSucsess = () => toast.success('Job Description Updated Successfully', { transition: Zoom });
const dailyactivityDeletedSuccess = () => toast.success('Daily Activity  Deleted Successfully', { transition: Zoom });
const dailyactivityUpdated = () => toast.info('Daily Activity  Updated Successfully', { transition: Zoom });

const weeklyactivityAddedSucsess = () => toast.success('Weeklyactivity Added Successfully', { transition: Zoom });
const weeklyjobdesAddedSucsess = () => toast.success('Job Description Updated Successfully', { transition: Zoom });
const weeklyactivityDeletedSuccess = () => toast.success('Weeklyactivity Deleted Successfully', { transition: Zoom });
const weeklyactivityUpdated = () => toast.info('Weeklyactivity Updated Successfully', { transition: Zoom });

const monthlyactivityAddedSucsess = () => toast.success('Monthlyactivity Added Successfully', { transition: Zoom });
const monthlyjobdesAddedSucsess = () => toast.success('Job Description Updated Successfully', { transition: Zoom });
const monthlyactivityDeletedSuccess = () => toast.success('Monthlyactivity Deleted Successfully', { transition: Zoom });
const monthlyactivityUpdated = () => toast.info('Monthlyactivity Updated Successfully', { transition: Zoom });

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* DepartmentList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.departmentList,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getDepartmentListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDepartmentListFailed(message));
    }
}

// Department Add

function* DepartmentAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.departmentAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        departmentAddedSucsess();
        yield put(getDepartmentAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDepartmentAddFailed(message));
    }
}

// Department Update

function* DepartmentUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.departmentUpdate + '/' + (data && data.dep_id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        departmentUpdated();
        yield put(getDepartmentUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDepartmentUpdateFailed(message));
    }
}

// Department Delete

function* DepartmentDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.departmentDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        departmentDeletedSuccess();
        yield put(getDepartmentDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDepartmentDeleteFailed(message));
    }
}
function* DailyactivityList({payload:data}) {
   console.log("lisssssssssssstak");
    let dep = data.dep_id || ''
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.dailyactivityList+`/`+dep,
        // data: sendData
    };
    

    try {
        const response = yield call(ApiCall, options);

        yield put(getDailyactivityListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailyactivityListFailed(message));
    }
}

// Dailyactivity Add

function* DailyactivityAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.dailyactivityAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        dailyactivityAddedSucsess();
        yield put(getDailyactivityAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailyactivityAddFailed(message));
    }
}
// Job des Add

function* DailyjobdesAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.jobdesAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        dailyjobdesAddedSucsess();
        yield put(getDailyjobdesAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailyjobdesAddFailed(message));
    }
}

// Dailyactivity Update

function* DailyactivityUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.dailyactivityUpdate + '/' + (data && data.dep_id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        dailyactivityUpdated();
        yield put(getDailyactivityUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailyactivityUpdateFailed(message));
    }
}

// Dailyactivity Delete

function* DailyactivityDelete({ payload: id }) {
    const user = getLoggedInUser();

   
}











function* WeeklyactivityList({payload:data}) {
   
    let dep = data.dep_id || ''
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.weeklyactivityList+`/`+dep,
        // data: sendData
    };
    

    try {
        const response = yield call(ApiCall, options);

        yield put(getWeeklyactivityListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getWeeklyactivityListFailed(message));
    }
}

// Weeklyactivity Add

function* WeeklyactivityAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.weeklyactivityAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        weeklyactivityAddedSucsess();
        yield put(getWeeklyactivityAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getWeeklyactivityAddFailed(message));
    }
}
// Job des Add

function* WeeklyjobdesAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.jobdesAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        weeklyjobdesAddedSucsess();
        yield put(getWeeklyjobdesAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getWeeklyjobdesAddFailed(message));
    }
}

// Weeklyactivity Update

function* WeeklyactivityUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.weeklyactivityUpdate + '/' + (data && data.dep_id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        weeklyactivityUpdated();
        yield put(getWeeklyactivityUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getWeeklyactivityUpdateFailed(message));
    }
}

// Weeklyactivity Delete

function* WeeklyactivityDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.weeklyactivityDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        weeklyactivityDeletedSuccess();
        yield put(getWeeklyactivityDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getWeeklyactivityDeleteFailed(message));
    }
}
function* MonthlyactivityList({payload:data}) {
   
    let dep = data.dep_id || ''
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        // url: endpoints.monthlyactivityList+`?dep_id=`+dep,
        url: endpoints.monthlyactivityList+`/`+dep,
        // data: sendData
    };
    

    try {
        const response = yield call(ApiCall, options);

        yield put(getMonthlyactivityListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getMonthlyactivityListFailed(message));
    }
}

// Monthlyactivity Add

function* MonthlyactivityAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.monthlyactivityAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        monthlyactivityAddedSucsess();
        yield put(getMonthlyactivityAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getMonthlyactivityAddFailed(message));
    }
}
// Job des Add

function* MonthlyjobdesAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.jobdesAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        monthlyjobdesAddedSucsess();
        yield put(getMonthlyjobdesAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getMonthlyjobdesAddFailed(message));
    }
}

// Monthlyactivity Update

function* MonthlyactivityUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.monthlyactivityUpdate + '/' + (data && data.dep_id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        monthlyactivityUpdated();
        yield put(getMonthlyactivityUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getMonthlyactivityUpdateFailed(message));
    }
}

// Monthlyactivity Delete

function* MonthlyactivityDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.monthlyactivityDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        monthlyactivityDeletedSuccess();
        yield put(getMonthlyactivityDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getMonthlyactivityDeleteFailed(message));
    }
}
function* UserDepartmentList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.userDepts,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getUserDepartmentListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getUserDepartmentListFailed(message));
    }
}
function* UserTeamList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.userTeams,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getUserTeamListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getUserTeamListFailed(message));
    }
}



function* getDailyActivityEdit({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.dailyactivityEditList,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        yield put(getDailyactivityListEditSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailyactivityListEditFailed(message));
    }
}

//add
function* getDailyActivityEditAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.dailyactivityAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        dailyactivityAddedSucsess();

        yield put(getDailyactivityAddEditSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailyactivityAddEditFailed(message));
    }
}


//update
function* getDailyActivityEditUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.dailyactivityeditUpdate,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        dailyactivityUpdated();

        yield put(getDailyactivityUpdateEditSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getDailyactivityUpdateEditFailed(message));
    }
}

export function* watchMonthlyactivityList(): any {
    yield takeEvery(MONTHLYACTIVITY_LIST, MonthlyactivityList);
}
export function* watchMonthlyactivityAdd(): any {
    yield takeEvery(MONTHLYACTIVITY_ADD, MonthlyactivityAdd);
}
export function* watchMonthlyjobdesAdd(): any {
    yield takeEvery(MONTHLYJOBDES_ADD, MonthlyjobdesAdd);
}
export function* watchMonthlyactivityUpdate(): any {
    yield takeEvery(MONTHLYACTIVITY_UPDATE, MonthlyactivityUpdate);
}
export function* watchMonthlyactivityDelete(): any {
    yield takeEvery(MONTHLYACTIVITY_DELETE, MonthlyactivityDelete);
}


export function* watchWeeklyactivityList(): any {
    yield takeEvery(WEEKLYACTIVITY_LIST, WeeklyactivityList);
}

export function* watchWeeklyactivityAdd(): any {
    yield takeEvery(WEEKLYACTIVITY_ADD, WeeklyactivityAdd);
}
export function* watchWeeklyjobdesAdd(): any {
    yield takeEvery(WEEKLYJOBDES_ADD, WeeklyjobdesAdd);
}
export function* watchWeeklyactivityUpdate(): any {
    yield takeEvery(WEEKLYACTIVITY_UPDATE, WeeklyactivityUpdate);
}
export function* watchWeeklyactivityDelete(): any {
    yield takeEvery(WEEKLYACTIVITY_DELETE, WeeklyactivityDelete);
}


export function* watchDepartmentList(): any {
    yield takeEvery(DEPARTMENT_LIST, DepartmentList);
}
export function* watchDepartmentAdd(): any {
    yield takeEvery(DEPARTMENT_ADD, DepartmentAdd);
}
export function* watchDepartmentUpdate(): any {
    yield takeEvery(DEPARTMENT_UPDATE, DepartmentUpdate);
}
export function* watchDepartmentDelete(): any {
    yield takeEvery(DEPARTMENT_DELETE, DepartmentDelete);
}
export function* watchDailyactivityList(): any {
    yield takeEvery(DAILYACTIVITY_LIST, DailyactivityList);
}
export function* watchDailyactivityAdd(): any {
    yield takeEvery(DAILYACTIVITY_ADD, DailyactivityAdd);
}
export function* watchDailyjobdesAdd(): any {
    yield takeEvery(DAILYJOBDES_ADD, DailyjobdesAdd);
}
export function* watchDailyactivityUpdate(): any {
    yield takeEvery(DAILYACTIVITY_UPDATE, DailyactivityUpdate);
}
export function* watchDailyactivityDelete(): any {
    yield takeEvery(DAILYACTIVITY_DELETE, DailyactivityDelete);
}

export function* watchUserDepartmentList(): any {
    yield takeEvery(USERDEPARTMENT_LIST, UserDepartmentList);
}

export function* watchUserTeamList(): any {
    yield takeEvery(USERTEAM_LIST, UserTeamList);
}
export function* watchgetDailyActivityEdit(): any {
    yield takeEvery(DAILYACTIVITYEDITLIST, getDailyActivityEdit);
}
export function* watchgetDailyActivityEditAdd(): any {
    yield takeEvery(DAILYACTIVITYEDITADD, getDailyActivityEditAdd);
}
export function* watchgetDailyActivityEditUpdate(): any {
    yield takeEvery(DAILYACTIVITYEDITUPDATE, getDailyActivityEditUpdate);
}

function* authSaga(): any {
    yield all([
        fork(watchDepartmentList),
        fork(watchDepartmentAdd),
        fork(watchDepartmentUpdate),
        fork(watchDepartmentDelete),
        fork(watchDailyactivityList),
        fork(watchDailyactivityAdd),
        fork(watchDailyjobdesAdd),
        fork(watchDailyactivityUpdate),
        fork(watchDailyactivityDelete),
        fork(watchWeeklyactivityList),
        fork(watchWeeklyactivityAdd),
        fork(watchWeeklyjobdesAdd),
        fork(watchWeeklyactivityUpdate),
        fork(watchWeeklyactivityDelete),
        fork(watchMonthlyactivityList),
        fork(watchMonthlyactivityAdd),
        fork(watchMonthlyjobdesAdd),
        fork(watchMonthlyactivityUpdate),
        fork(watchMonthlyactivityDelete),
        fork(watchUserDepartmentList),
        fork(watchUserTeamList),
        fork(watchgetDailyActivityEdit),
        fork(watchgetDailyActivityEditAdd),
        fork(watchgetDailyActivityEditUpdate),
    ]);
}

export default authSaga;
