// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';

import { PAY_LIST, PAYROLL_ADD, PAYROLL_UPDATE, PAYROLL_DELETE,PAYROLL_EMPLOYEE } from './constants';

import {
    getPayrollListSuccess,
    getPayrollListFailed,
    getPayrollAddSuccess,
    getPayrollAddFailed,
    getPayrollUpdateSuccess,
    getPayrollUpdateFailed,
    getPayrollDeleteSuccess,
    getPayrollDeleteFailed,
    getPayrollEmployeeListSuccess,
    getPayrollEmployeeListFailed   
} from './actions';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLoggedInUser } from '../../helpers/authUtils';

const PayrollAddedSucsess = () => toast.success('Salary Added Successfully', { transition: Zoom });
const PayrollDeletedSuccess = () => toast.success('Salary Deleted Successfully', { transition: Zoom });
const PayrollUpdated = () => toast.info('Salary Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* PayrollList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.PayrollList,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getPayrollListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getPayrollListFailed(message));
    }
}

// Payroll Add

function* PayrollAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.PayrollAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        PayrollAddedSucsess();
        yield put(getPayrollAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getPayrollAddFailed(message));
    }
}

// Payroll Update

function* PayrollUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.PayrollUpdate + '/' + (data && data.id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        PayrollUpdated();
        yield put(getPayrollUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getPayrollUpdateFailed(message));
    }
}

// Payroll Delete

function* PayrollDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.PayrollDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        PayrollDeletedSuccess();
        yield put(getPayrollDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getPayrollDeleteFailed(message));
    }
}



function* PayrollEmployeeData() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.employeeSalaryList,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getPayrollEmployeeListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getPayrollEmployeeListFailed(message));
    }
}

export function* watchPayrollList(): any {
    yield takeEvery(PAY_LIST, PayrollList);
}
export function* watchPayrollAdd(): any {
    yield takeEvery(PAYROLL_ADD, PayrollAdd);
}
export function* watchPayrollUpdate(): any {
    yield takeEvery(PAYROLL_UPDATE, PayrollUpdate);
}
export function* watchPayrollDelete(): any {
    yield takeEvery(PAYROLL_DELETE, PayrollDelete);
}

export function* watchPayrollEmployeeData(): any {
    yield takeEvery(PAYROLL_EMPLOYEE, PayrollEmployeeData);
}


function* authSaga(): any {
    yield all([
      //  fork(watchPayrollEmployeeData),
        fork(watchPayrollList),
        fork(watchPayrollAdd),
        fork(watchPayrollUpdate),
        fork(watchPayrollDelete),
       
    ]);
}

export default authSaga;
