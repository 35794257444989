// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';

import { ROLE_LIST, ROLE_ADD, ROLE_UPDATE, ROLE_DELETE } from './constants';

import {
    getRoleListSuccess,
    getRoleListFailed,
    getRoleAddSuccess,
    getRoleAddFailed,
    getRoleUpdateSuccess,
    getRoleUpdateFailed,
    getRoleDeleteSuccess,
    getRoleDeleteFailed,
} from './actions';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLoggedInUser } from '../../helpers/authUtils';

const RoleAddedSucsess = () => toast.success('Designation Added Successfully', { transition: Zoom });
const RoleDeletedSuccess = () => toast.success('Designation Deleted Successfully', { transition: Zoom });
const RoleUpdated = () => toast.info('Designation Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* RoleList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.RoleList,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getRoleListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRoleListFailed(message));
    }
}

// Role Add

function* RoleAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.RoleAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        RoleAddedSucsess();
        yield put(getRoleAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRoleAddFailed(message));
    }
}

// Role Update

function* RoleUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.RoleUpdate + '/' + (data && data.role_id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        RoleUpdated();
        yield put(getRoleUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRoleUpdateFailed(message));
    }
}

// Role Delete

function* RoleDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.RoleDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        RoleDeletedSuccess();
        yield put(getRoleDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getRoleDeleteFailed(message));
    }
}

export function* watchRoleList(): any {
    yield takeEvery(ROLE_LIST, RoleList);
}
export function* watchRoleAdd(): any {
    yield takeEvery(ROLE_ADD, RoleAdd);
}
export function* watchRoleUpdate(): any {
    yield takeEvery(ROLE_UPDATE, RoleUpdate);
}
export function* watchRoleDelete(): any {
    yield takeEvery(ROLE_DELETE, RoleDelete);
}

function* authSaga(): any {
    yield all([
        fork(watchRoleList),
        fork(watchRoleAdd),
        fork(watchRoleUpdate),
        fork(watchRoleDelete),
    ]);
}

export default authSaga;
