// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';

import { CLIENT_LIST, CLIENT_ADD, CLIENT_UPDATE, CLIENT_DELETE } from './constants';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    getClientListSuccess,
    getClientListFailed,
    getClientAddSuccess,
    getClientAddFailed,
    getClientUpdateSuccess,
    getClientUpdateFailed,
    getClientDeleteSuccess,
    getClientDeleteFailed,
} from './actions';

import { getLoggedInUser } from '../../helpers/authUtils';

const ClientAddedSucsess = () => toast.success('Client Added Successfully', { transition: Zoom });
const ClientDeletedSuccess = () => toast.success('Client Deleted Successfully', { transition: Zoom });
const ClientUpdated = () => toast.info('Client Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* getClientList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.clientList,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getClientListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getClientListFailed(message));
    }
}

// Client Add

function* ClientAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.clientAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        ClientAddedSucsess();
     //   console.log(response.data);
        yield put(getClientAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getClientAddFailed(message));
    }
}

// Client Update

function* ClientUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.clientUpdate + '/' + (data && data.id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        ClientUpdated();
        yield put(getClientUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getClientUpdateFailed(message));
    }
}

// Client Delete

function* ClientDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.clientDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        ClientDeletedSuccess();
        yield put(getClientDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getClientDeleteFailed(message));
    }
}

export function* watchgetClientList(): any {
    yield takeEvery(CLIENT_LIST, getClientList);
}
export function* watchClientAdd(): any {
    yield takeEvery(CLIENT_ADD, ClientAdd);
}
export function* watchClientUpdate(): any {
    yield takeEvery(CLIENT_UPDATE, ClientUpdate);
}
export function* watchClientDelete(): any {
    yield takeEvery(CLIENT_DELETE, ClientDelete);
}

function* authSaga(): any {
    yield all([fork(watchgetClientList), fork(watchClientAdd), fork(watchClientUpdate), fork(watchClientDelete)]);
}

export default authSaga;
