// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';

import { CLIENT_REVENUE_LIST, CLIENT_REVENUE_ADD, CLIENT_REVENUE_UPDATE, CLIENT_REVENUE_DELETE ,COUNTRY_LIST,
    COUNTRY_LIST_SUCCESS,
    COUNTRY_LIST_FAILED,} from './constants';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    getClientRevenueListSuccess,
    getClientRevenueListFailed,
    getClientRevenueAddSuccess,
    getClientRevenueAddFailed,
    getClientRevenueUpdateSuccess,
    getClientRevenueUpdateFailed,
    getClientRevenueDeleteSuccess,
    getClientRevenueDeleteFailed,
    getCountryList,
    getCountryListSuccess,
    getCountryListFailed

} from './actions';

import { getLoggedInUser } from '../../helpers/authUtils';

const ClientAddedSucsess = () => toast.success('Client Added Successfully', { transition: Zoom });
const ClientDeletedSuccess = () => toast.success('Client Deleted Successfully', { transition: Zoom });
const ClientUpdated = () => toast.info('Client Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* getClientRevenueList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.clientRevenueList,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getClientRevenueListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getClientRevenueListFailed(message));
    }
}

// Client Add

function* ClientAdd({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'POST',
        url: endpoints.clientRevenueAdd,
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        ClientAddedSucsess();
     //   console.log(response.data);
        yield put(getClientRevenueAddSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getClientRevenueAddFailed(message));
    }
}

// Client Update

function* ClientUpdate({ payload: data }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'PUT',
        url: endpoints.clientRevenueUpdate + '/' + (data && data.id),
        data: data,
    };

    try {
        const response = yield call(ApiCall, options);
        ClientUpdated();
        yield put(getClientRevenueUpdateSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getClientRevenueUpdateFailed(message));
    }
}

// Client Delete

function* ClientDelete({ payload: id }) {
    const user = getLoggedInUser();

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'DELETE',
        url: endpoints.clientRevenueDelete + '/' + id,
    };

    try {
        const response = yield call(ApiCall, options);
        ClientDeletedSuccess();
        yield put(getClientRevenueDeleteSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getClientRevenueDeleteFailed(message));
    }
}
function* getCountryAllList() {
    const user = getLoggedInUser();
    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user.token,
        },
        method: 'GET',
        url: endpoints.getCountry,
        // data: sendData
    };

    try {
        const response = yield call(ApiCall, options);

        yield put(getCountryListSuccess(response.data));
    } catch (error) {
        let message;
        switch (error.response.status) {
            case 500:
                message = 'Internal Server Error';
                WarnFields(message);
                break;
            case 401:
                message = 'Invalid credentials';
                WarnFields(message);
                break;
            case 400:
                message = error.response.data && error.response.data.error;
                WarnFields(message);
                break;
            default:
                message = error;
        }
        yield put(getCountryListFailed(message));
    }
}

export function* watchgetClientRevenueList(): any {
    yield takeEvery(CLIENT_REVENUE_LIST, getClientRevenueList);
}
export function* watchClientRevenueAdd(): any {
    yield takeEvery(CLIENT_REVENUE_ADD, ClientAdd);
}
export function* watchClientRevenueUpdate(): any {
    yield takeEvery(CLIENT_REVENUE_UPDATE, ClientUpdate);
}
export function* watchClientRevenueDelete(): any {
    yield takeEvery(CLIENT_REVENUE_DELETE, ClientDelete);
}
export function* watchGetCountryList(): any {
    yield takeEvery(COUNTRY_LIST, getCountryAllList);
}

function* authSaga(): any {
    yield all([fork(watchgetClientRevenueList), fork(watchClientRevenueAdd), fork(watchClientRevenueUpdate), fork(watchClientRevenueDelete),fork(watchGetCountryList)]);
}

export default authSaga;
