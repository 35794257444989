// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import { ApiCall } from '../../services/index';
import { endpoints } from '../../services/endpoints';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {    MONTHLYACTIVITYDATA_LIST,
    MONTHLYACTIVITYDATA_LIST_SUCCESS,
    MONTHLYACTIVITYDATA_LIST_FAILED, } from './constants';

import {
    getMonthlyactivitydataListSuccess,
    getMonthlyactivitydataListFailed,

} from './actions';

import { getLoggedInUser } from '../../helpers/authUtils';

const monthlyactivityAddedSucsess = () => toast.success('Monthlyactivity Added Successfully', { transition: Zoom });
const monthlyjobdesAddedSucsess = () => toast.success('Job Description Updated Successfully', { transition: Zoom });
const monthlyactivityDeletedSuccess = () => toast.success('Monthlyactivity Deleted Successfully', { transition: Zoom });
const monthlyactivityUpdated = () => toast.info('Monthlyactivity Updated Successfully', { transition: Zoom });
const emptyAllFields = () => toast.warning('Please fill all fields', { transition: Zoom });
const WarnFields = (msg) => toast.error(msg, { transition: Zoom });
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* MonthlyactivityDataList({payload:data}) {
    //   let emp = data.user_id;
    //   let date = data.month_pick;
        const user = getLoggedInUser();
        let options = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + user.token,
            },
            method: 'POST',
            // url: endpoints.dailyreportList+`?user_id=`+emp+`&date_daily=`+date,
            url: endpoints.reportmonthData,
            // url: endpoints.dailyreportList,
             data: {
    
                "user_id":data.user_id,
                "month":data.month,
                
    
             }
        };
    
        try {
            const response = yield call(ApiCall, options);
    
            yield put(getMonthlyactivitydataListSuccess(response.data));
        } catch (error) {
            let message;
            switch (error.response.status) {
                case 500:
                    message = 'Internal Server Error';
                    WarnFields(message);
                    break;
                case 401:
                    message = 'Invalid credentials';
                    WarnFields(message);
                    break;
                case 400:
                    message = error.response.data && error.response.data.error;
                    WarnFields(message);
                    break;
                default:
                    message = error;
            }
            yield put(getMonthlyactivitydataListFailed(message));
        }
    }



export function* watchMonthlyactivityDataList(): any {
    yield takeEvery(MONTHLYACTIVITYDATA_LIST, MonthlyactivityDataList);
}

function* authSaga(): any {
    yield all([
        fork(watchMonthlyactivityDataList),
       
    ]);
}

export default authSaga;
